<template>
  <v-card :height="200">
    <v-card-title class="mb-0 pb-1">
      Connection Fee Information
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-container class="mt-0">
        <v-row>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Month
              </h4>
              <h4 class="font-weight-medium">
                {{ connectionFee.month | formatMonth }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Bill
              </h4>
              <h4 class="font-weight-medium">
                Ksh {{ connectionFee.amount | formatCurrency }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Amount paid
              </h4>
              <h4 class="font-weight-medium">
                Ksh {{ connectionFee.connection_fee_payments_sum_amount_paid ? connectionFee.connection_fee_payments_sum_amount_paid : 0 | formatCurrency }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Credit applied
              </h4>
              <h4 class="font-weight-medium">
                Ksh {{ connectionFee.connection_fee_payments_sum_credit ? connectionFee.connection_fee_payments_sum_credit: 0 | formatCurrency }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Status
              </h4>
              <h4 class="font-weight-medium">
                {{ connectionFee.status | formatMeterReadingStatus }}
              </h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import DetailsTextShimmer from '@/components/partials/shimmers/DetailsTextShimmer.vue'

export default {
  components: {
    'details-text-shimmer': DetailsTextShimmer,
  },
  props: {
    connectionFee: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style>

</style>
