<template>
  <div class="transactions">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <connection-fee-details-card
      :connection-fee="details"
      :is-loading="isLoading"
    /><br>
    <connection-fee-payment-details-card
      :connection-fee-payments="details.connection_fee_payments"
      :is-loading="isLoading"
    /><br>
    <user-details-card
      :user="details.user"
      :is-loading="isLoading"
    />
  </div>
</template>
<script>
import axios from 'axios'
import ConnectionFeeDetailsCard from '@/components/details-card/ConnectionFeeDetailsCard.vue'
import ConnectionFeePaymentDetailsCard from '@/components/details-card/ConnectionFeePaymentDetailsCard.vue'
import UserDetailsCard from '@/components/details-card/UserDetailsCard.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    UserDetailsCard,
    ConnectionFeeDetailsCard,
    ConnectionFeePaymentDetailsCard,
  },
  data() {
    return {
      search: '',
      details: { user: {}, connection_fee_payments: [] },
      isLoading: false,
      meterReadingId: this.$route.params.id,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Connection Fee',
          disabled: false,
          to: { name: 'connection-fee' },
        },
      ],
    }
  },
  mounted() {
    this.getDetails()
    if (this.meterReadingId) {
      this.breadcrumbs.push(
        {
          text: this.meterReadingId,
          disabled: true,
          to: { name: 'connection-fee-details' },
        },
      )
    }
  },
  methods: {
    getDetails() {
      this.isLoading = true
      axios
        .get(`connection-fees/${this.meterReadingId}`)
        .then(response => {
          this.details = response.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>
